<template>
  <span @click="handleDelete" class="delete-city">
    <i v-if="!loading" class="fa fa-trash-alt"></i>
    <i v-else class="fa fa-circle-notch loading"></i>
  </span>
</template>

<script>
import swal from 'sweetalert'
export default {
  props: {
    cityId: { type: [Number, String], required: true }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    handleDelete () {
      swal({
        icon: 'warning',
        title: 'Atenção',
        text: 'Certeza que quer deletar a cidade?',
        buttons: [
          {
            text: 'Cancelar',
            value: false,
            visible: true,
            className: 'cancel-action'
          },
          {
            text: 'Excluir',
            value: true,
            visible: true,
            className: 'delete-action'
          }
        ]
      }).then(response => {
        if (response) this.deleteCity()
      })
    },
    async deleteCity () {
      if (this.loading) return
      this.loading = true
      const url = `materials/${this.cityId}/cities`
      try {
        await this.$http.delete(url)
        swal({
          title: 'Sucesso',
          text: 'Cidade excluída com sucesso.',
          icon: 'success',
          buttons: false,
          timer: 1500
        })
        this.$emit('deleted')
      } catch (error) {
        swal({
          title: 'Ooops...',
          text: 'Erro ao excluir cidade.',
          icon: 'error',
          buttons: false,
          timer: 3000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="sass">
.swal-button
  &.delete-action
    color: #ffffff
    background: #ff0037 !important

  &.cancel-action
    color: #16b666
    background: #e5f7ec !important
</style>

<style lang="sass" scoped>
.delete-city
  margin-left: auto

  .fa-trash-alt
    color: #ff0037
    font-size: 22px !important

  .fa-circle-notch
    animation: loading linear 1s infinite

@keyframes loading
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
